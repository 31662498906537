import React, { useContext } from "react";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import { Logout } from "../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Sidebar() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const { drawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Section as="aside" className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}>
      <ul class="mc-sidebar-menu-list">
        <li className={location.pathname == "/crm" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/crm" onClick={() => navigate("/crm")}>
            <i class="material-icons">dashboard</i>
            <span>Dashboard</span>
          </a>
        </li>

        <li className={location.pathname == "/sliders" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/sliders" onClick={() => navigate("/sliders")}>
            <i class="material-icons">image</i>
            <span>Sliders</span>
          </a>
        </li>

        <li className={location.pathname == "/faqs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/faqs" onClick={() => navigate("/faqs")}>
            <i class="material-icons">help</i>
            <span> FAQ</span>
          </a>
        </li>

        <li className={location.pathname == "/blogs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/blogs" onClick={() => navigate("/blogs")}>
            <i class="material-icons">description</i>
            <span>Blogs</span>
          </a>
        </li>

        <li className={location.pathname == "/destinations" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/destinations" onClick={() => navigate("/destinations")}>
            <i class="material-icons">pin_drop</i>
            <span>Services </span>
          </a>
        </li>

        {/* <li className={location.pathname == "/products" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/products" onClick={() => navigate("/products")}>
            <i class="material-icons">keyboard_command_key</i>
            <span>Products</span>
          </a>
        </li> */}

        <li className={location.pathname == "/testimonials" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/testimonials" onClick={() => navigate("/testimonials")}>
            <i class="material-icons">star</i>
            <span>Testimonials</span>
          </a>
        </li>

        <li className={location.pathname == "/settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/settings" onClick={() => navigate("/settings")}>
            <i class="material-icons">settings</i>
            <span>Settings</span>
          </a>
        </li>
      </ul>
      <Logout />
    </Section>
  );
}
