import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Documentation, ChangeLog, Error } from "./pages/supports";
import { Avatars, Alerts, Buttons, Charts, Tables, Fields, Headings, Colors } from "./pages/blocks";
import {
  ManageBlogs,
  Blogs,
  FAQs,
  Experience,
  Ecommerce,
  Analytics,
  CRM,
  ForgotPassword,
  Register,
  Login,
  Sliders,
  Destinations,
  UserList,
  UserProfile,
  MyAccount,
  ProductList,
  ProductView,
  ProductUpload,
  InvoiceList,
  InvoiceDetails,
  OrderList,
  Message,
  Notification,
  BlankPage,
  Settings,
  Products,
  ManageProducts,
  Subdomains,
  ManageSubdomains,
  ManageSubdomainProducts,
  OtherFAQS,
  Roles,
  Users,
  Testimonials,
  PartnerWithUs,
  SetPassword,
} from "./pages/master";
import Counter from "./Counter";
import { Provider } from "react-redux";
import { store } from "./store";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          }}
        />

        <BrowserRouter>
          <Routes>
            {/* master Pages */}

            <Route path="/crm" element={<CRM />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/destinations" element={<Destinations />} />
            <Route path="/experiences" element={<Experience />} />
            <Route path="/products" element={<Products />} />
            <Route path="/manage-products" element={<ManageProducts />} />
            <Route path="/sliders" element={<Sliders />} />
            <Route path="/partner-with-us" element={<PartnerWithUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/manage-blogs" element={<ManageBlogs />} />
            <Route path="/subdomains" element={<Subdomains />} />
            <Route path="/manage-subdomains" element={<ManageSubdomains />} />
            <Route path="/manage-subdomain-products" element={<ManageSubdomainProducts />} />
            <Route path="/other-faqs" element={<OtherFAQS />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/users" element={<Users />} />
            <Route path="/set-password" element={<SetPassword />} />

            <Route path="/counter" element={<Counter />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/product-view" element={<ProductView />} />
            <Route path="/product-upload" element={<ProductUpload />} />
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/invoice-details" element={<InvoiceDetails />} />
            <Route path="/order-list" element={<OrderList />} />
            <Route path="/message" element={<Message />} />
            <Route path="/notification" element={<Notification />} />

            <Route path="/blank-page" element={<BlankPage />} />

            {/* Blocks Pages */}
            <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/alerts" element={<Alerts />} />

            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Login />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/changelog" element={<ChangeLog />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
