import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor, { buttonList } from "suneditor-react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "./../../components";
import { Anchor } from "./../../components/elements";

export default function ManageBlog() {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  ////////////////////

  const [activityLevel, setActivityLevel] = useState(1);
  const [uploadData, setUploadData] = useState(false);
  const [Saving, setSaving] = useState(false);

  const [expereance, setExpereance] = useState("");
  const [subExpereance, setSubExpereance] = useState("");

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");

  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState("");
  const [description, setDescription] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState("overView");
  const [coverImage, setCoverImage] = useState("");

  const [type, setType] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  ////////////////////

  async function fetchProduct() {
    const options = {
      headers: { authorization: token },
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    if (urlParams && urlParams.get("id")) {
      setDisabled(false);
      const Response = await axios.get(process.env.REACT_APP_API_URL + "app/blogs/detail/" + id, options);
      if (Response && Response.data.success) {
        if (Response.data.result) {
          if (Response.data.result.parentDestination) {
          }

          setTitle(Response.data.result.title);
          setSlug(Response.data.result.slug);
          setMetaDesc(Response.data.result.metaDesc);
          setDescription(Response.data.result.desc);

          setActivityLevel(Response.data.result.activityLevel);
          setSubExpereance(Response.data.result.subExpereance);
          setCoverImage(Response.data.result.image);
          setType(Response.data.result.type);
        }
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  async function handleOverview() {
    if (!title && title == "") {
      toast.error("Enter title");
      return;
    }

    if (!description && description == "") {
      toast.error("Select description");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var insertData = [];

    insertData["parentDestination"] = destination;
    insertData["subDestination"] = subDestination;
    insertData["title"] = title;
    insertData["desc"] = description;
    insertData["type"] = type;
    insertData["activityLevel"] = activityLevel;
    insertData["metaDesc"] = metaDesc;
    insertData["slug"] = slug;
    if (expereance && subExpereance) {
      insertData["parentExpereance"] = expereance;
      insertData["subExpereance"] = subExpereance;
    }

    if (disabled) {
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/blogs/add", qs.stringify(insertData), options);
      if (Response.data.status == "success") {
        setSaving(false);
        setDisabled(false);
        setSelectedTab("itinerary");
        navigate("/manage-blogs?id=" + Response.data.blogId);
        toast.success(Response.data.message);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/blogs/update/" + id, qs.stringify(insertData), options);
      if (Response.data.status == "success") {
        setSaving(false);
        toast.success(Response.data.message);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  // On File Select Load It To Memory
  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  async function handleCoverImage() {
    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();
    uploadFormData.append("upload", uploadData);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/blogs/update-cover/" + id, uploadFormData, options);
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage blog"}>
              <li className="mc-breadcrumb-item">
                <Anchor icon={"chevron_left"} text={"Back"} href={"/blogs"} className="mc-btn primary" />
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Tabs defaultActiveKey={selectedTab} className="mb-3">
                  <Tab eventKey="overView" onClick={() => setSelectedTab("overView")} title="Over View">
                    <div>
                      <div className="form-group mt-3">
                        <label>Title</label>
                        <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                      </div>

                      <div className="form-group mt-3">
                        <label>Slug</label>
                        <input type="text" className="form-control" value={slug} onChange={(e) => setSlug(e.target.value)} />
                      </div>

                      <div className="form-group mt-3">
                        <label>Description</label>
                        <SunEditor
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setDescription}
                          setContents={description}
                          height={400}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Meta Description</label>
                        <textarea className="form-control" value={metaDesc} onChange={(e) => setMetaDesc(e.target.value)}></textarea>
                      </div>

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save & Proceed</>}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="coverImage" onClick={() => setSelectedTab("coverImage")} title="Cover Image" disabled={disabled}>
                    <div>
                      <LabelField label="Image (600x350)" type="file" fieldSize="w-100 h-md" accept="image/*" onChange={(e) => fileHandler(e)} />

                      {coverImage != "" && (
                        <div>
                          <img src={process.env.REACT_APP_IMG_URL + "blog/optimized/" + coverImage} className="img-fluid mt-3" style={{ height: "100px" }} />
                        </div>
                      )}

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleCoverImage()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save </>}
                      </button>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
