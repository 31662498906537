import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "suneditor/dist/css/suneditor.min.css";
import { Breadcrumb } from "./../../components";
import DomainProducts from "../blocks/domainProducts";
import { Anchor } from "./../../components/elements";
import { useNavigate } from "react-router-dom";
import { Button } from "./../../components/elements";
import SunEditor, { buttonList } from "suneditor-react";
export default function ProductList() {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  ////////////////////

  const [Saving, setSaving] = useState(false);
  const [marketingCollateral, setMarketingCollateral] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [address, setAddress] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [website, setWebsite] = useState("");
  const [subdomain, setSubDomain] = useState("");
  const [about, setAbout] = useState("");
  const [about2, setAbout2] = useState("");
  const [visualIdentity, setVisualIdentity] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState("");
  const [logo, setLogo] = useState(false);
  const [profileImage, setProfileImage] = useState(false);
  const [profileImage2, setProfileImage2] = useState(false);
  const [homeImage, setHomeImage] = useState(false);
  const [aboutImage, setAboutImage] = useState(false);
  const [tripImage, setTripImage] = useState(false);
  const [instaImage, setInstaImage] = useState(false);
  const [logoFileName, setLogoFileName] = useState("");
  const [profileFileName, setProfileFileName] = useState("");
  const [profile2FileName, setProfile2FileName] = useState("");
  const [homeFileName, setHomeFileName] = useState("");
  const [aboutFileName, setAboutFileName] = useState("");
  const [tripFileName, setTripFileName] = useState("");
  const [instagramFileName, setInstagramFileName] = useState("");
  const [status, setStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [designation2, setDesignation2] = useState("");
  const [designation1, setDesignation1] = useState("");
  const [company, setCompany] = useState("");
  const [codeSnipt, setCodeSnipt] = useState("");
  const [additionalCustomizations, setAdditionalCustomizations] = useState("No");
  const [marketingMaterial, setMarketingMaterial] = useState("");
  const [cohostEmail, setCohostEmail] = useState("");
  ////////////////////

  const handleCheckboxChange = (value) => {
    // Check if the checkbox is already in the array
    if (marketingMaterial.includes(value)) {
      // If yes, remove it
      setMarketingMaterial(marketingMaterial.filter((item) => item !== value));
    } else {
      // If not, add it
      setMarketingMaterial([...marketingMaterial, value]);
    }
  };

  useEffect(() => {
    fetchSubdomain();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setSelectedId(urlParams.get("id"));
  }, []);

  function logoHandler(e) {
    const file = e.currentTarget.files[0];
    setLogo(file);
  }

  function profileImageHandler(e) {
    const file = e.currentTarget.files[0];
    setProfileImage(file);
  }

  function profileImage2Handler(e) {
    const file = e.currentTarget.files[0];
    setProfileImage2(file);
  }

  function homeImageHandler(e) {
    const file = e.currentTarget.files[0];
    setHomeImage(file);
  }

  function aboutImageHandler(e) {
    const file = e.currentTarget.files[0];
    setAboutImage(file);
  }

  function tripImageHandler(e) {
    const file = e.currentTarget.files[0];
    setTripImage(file);
  }

  function instagramImageHandler(e) {
    const file = e.currentTarget.files[0];
    setInstaImage(file);
  }

  async function handleSave() {
    if (!marketingCollateral && marketingCollateral == "") {
      toast.error("Select marketing collateral request for");
      return;
    }

    if (marketingCollateral == "cohost" && !designation1 && designation1 == "") {
      toast.error("Enter designation of host one");
      return;
    }

    if (marketingCollateral == "cohost" && !designation2 && designation2 == "") {
      toast.error("Enter designation of host two");
      return;
    }

    if (marketingCollateral == "cohost" && !name2 && name2 == "") {
      toast.error("Enter name of host two");
      return;
    }

    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      return toast.error("Please enter valid email");
    }

    if (marketingCollateral == "cohost" && cohostEmail && cohostEmail != "" && !re.test(cohostEmail)) {
      return toast.error("Please enter valid co-host email");
    }

    if (!name && name == "") {
      toast.error("Enter name");
      return;
    }

    if (!subdomain && subdomain == "") {
      toast.error("Enter retreat website url");
      return;
    }

    if (!about && about == "") {
      toast.error("Enter about me bio / about of host one");
      return;
    }

    if (marketingCollateral == "cohost" && !about2 && about2 == "") {
      toast.error("Enter about me bio of host two");
      return;
    }

    if (marketingCollateral == "cohost" && !company && company == "") {
      toast.error("Enter company name  ");
      return;
    }

    setSaving(true);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };
    const avlbl = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/check", qs.stringify({ subdomain: subdomain, isAdmin: "yes", id: id }));
    if (avlbl.data.status == "false") {
      toast.error(avlbl.data.message);
      setSaving(false);
      return;
    }

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/sub-domain/update/" + id,
      qs.stringify({
        email: email,
        name: name,
        address: address,
        facebook: facebook,
        instagram: instagram,
        websiteURL: website,
        retreatURL: subdomain,
        aboutMe: about,
        aboutMe2: about2,
        visualIdentity: visualIdentity,
        specialRequirement: specialRequirements,
        marketingCollateral: marketingCollateral,
        status: status,
        youtube: youtube,
        linkedin: linkedin,
        tiktok: tiktok,
        pinterest: pinterest,
        designation1: designation1,
        designation2: designation2,
        name2: name2,
        company: company,
        codeSnipt: codeSnipt,
        additionalCustomizations: additionalCustomizations,
        marketingMaterial: marketingMaterial.join(","),
        cohostEmail: cohostEmail,
      }),
      options
    );
    if (Response.data.status == "success") {
      const options = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      if (logo) {
        const logoData = new FormData();
        logoData.append("upload", logo);
        logoData.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadLogo", logoData, options);

        if (response.data.status == "success") {
          setLogoFileName(response.data.filename);
        }
      }

      // if (homeImage) {
      //   const uploadHomeImageData = new FormData();
      //   uploadHomeImageData.append("upload", homeImage);
      //   uploadHomeImageData.append("id", id);
      //   var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadHomeImage", uploadHomeImageData, options);

      //   if (response.data.status == "success") {
      //     setHomeFileName(response.data.filename);
      //   }
      // }

      if (profileImage) {
        const profileImageData = new FormData();
        profileImageData.append("upload", profileImage);
        profileImageData.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadProfilePicture", profileImageData, options);

        if (response.data.status == "success") {
          setProfileFileName(response.data.filename);
        }
      }

      if (marketingCollateral == "cohost" && profileImage2) {
        const profileImageData2 = new FormData();
        profileImageData2.append("upload", profileImage2);
        profileImageData2.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadProfilePicture2", profileImageData2, options);

        if (response.data.status == "success") {
          setProfile2FileName(response.data.filename);
        }
      }

      if (aboutImage) {
        const aboutImageData = new FormData();
        aboutImageData.append("upload", aboutImage);
        aboutImageData.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadAboutPicture", aboutImageData, options);

        if (response.data.status == "success") {
          setAboutFileName(response.data.filename);
        }
      }

      if (tripImage) {
        const tripImageData = new FormData();
        tripImageData.append("upload", tripImage);
        tripImageData.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadTripPicture", tripImageData, options);

        if (response.data.status == "success") {
          setTripFileName(response.data.filename);
        }
      }

      if (instaImage) {
        const instaImageData = new FormData();
        instaImageData.append("upload", instaImage);
        instaImageData.append("id", id);
        var response = await axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/uploadInstaPicture", instaImageData, options);
        if (response.data.status == "success") {
          setInstagramFileName(response.data.filename);
        }
      }

      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchSubdomain() {
    const options = {
      headers: { authorization: token },
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    if (urlParams && urlParams.get("id")) {
      const Response = await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/detail/" + id, options);
      if (Response && Response.data.success) {
        if (Response.data.result) {
          setMarketingCollateral(Response.data.result.marketingCollateral);
          setMarketingCollateral(Response.data.result.marketingCollateral);
          setEmail(Response.data.result.email);
          setName(Response.data.result.name);
          setName2(Response.data.result.name2);
          setAddress(Response.data.result.address);
          setFacebook(Response.data.result.facebook);
          setInstagram(Response.data.result.instagram);
          setYoutube(Response.data.result.youtube);
          setLinkedin(Response.data.result.linkedin);
          setWebsite(Response.data.result.websiteURL);
          setSubDomain(Response.data.result.retreatURL);
          setAbout(Response.data.result.aboutMe);
          setAbout2(Response.data.result.aboutMe2);
          setVisualIdentity(Response.data.result.visualIdentity);
          setSpecialRequirements(Response.data.result.specialRequirement);
          setLogoFileName(Response.data.result.logo);
          setProfileFileName(Response.data.result.profilePicture);
          setProfile2FileName(Response.data.result.profilePicture2);
          // setHomeFileName(Response.data.result.homeImage);
          setAboutFileName(Response.data.result.aboutImage);
          setTripFileName(Response.data.result.tripImage);
          setInstagramFileName(Response.data.result.instaImage);
          setStatus(Response.data.result.status);
          setDesignation2(Response.data.result.designation2);
          setDesignation1(Response.data.result.designation1);
          setCompany(Response.data.result.company);
          setCodeSnipt(Response.data.result.codeSnipt);
          setAdditionalCustomizations(Response.data.result.additionalCustomizations);
          setMarketingMaterial(Response.data.result.marketingMaterial.split(","));
          setCohostEmail(Response.data.result.cohostEmail);
        }
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage sub-domains"}>
              <li className="mc-breadcrumb-item" style={{ textTransform: "lowercase" }}>
                {subdomain + ".iamfit4travel.com"}
              </li>
              <li className="mc-breadcrumb-item">
                <Anchor icon={"chevron_left"} text={"Back"} href={"/subdomains"} className="mc-btn primary" />
              </li>
              <li className="mc-breadcrumb-item">
                <Button icon={"save"} className="mc-btn primary w-100" disable={!Saving} onClick={() => handleSave()}>
                  {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
                </Button>
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Tabs defaultActiveKey={"overView"} className="mb-3">
                  <Tab eventKey="overView" title="Over View">
                    <div>
                      <div class="">
                        <div class="row">
                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Status</label>

                              <select class="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                                <option value="0"> Pending </option>
                                <option value="1"> Live </option>
                                <option value="2"> Inactive </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Are you hosting the retreat solo or with a co-host?</label>
                              <select class="form-control" onChange={(e) => setMarketingCollateral(e.target.value)} value={marketingCollateral}>
                                <option value=""> -- Select --</option>
                                <option value="individual"> Solo Host </option>
                                <option value="cohost"> Co-Hosts </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Email address</label>
                              <input type="email" class="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
                              <small class="form-text text-muted">The email that will be used on the retreat website for travelers</small>
                            </div>
                          </div>

                          {marketingCollateral == "cohost" && (
                            <>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Co-Host Email address</label>
                                  <input type="email" class="form-control" onChange={(e) => setCohostEmail(e.target.value)} value={cohostEmail} />
                                </div>
                              </div>
                            </>
                          )}

                          {marketingCollateral == "cohost" ? (
                            <>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Company name</label>
                                  <input type="text" class="form-control" onChange={(e) => setCompany(e.target.value)} value={company} />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Your name or the company name</label>
                                  <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} value={name} />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Co-host name (Host 2)</label>
                                  <input type="text" class="form-control" onChange={(e) => setName2(e.target.value)} value={name2} />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Your name or the company name</label>
                                  <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} value={name} />
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Address</label>
                              <textarea class="form-control" onChange={(e) => setAddress(e.target.value)} value={address}></textarea>
                              <small class="form-text text-muted">full address or just city and state for retreat website</small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Facebook link</label>
                              <input type="url" class="form-control" onChange={(e) => setFacebook(e.target.value)} value={facebook} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Instagram link</label>
                              <input type="url" class="form-control" onChange={(e) => setInstagram(e.target.value)} value={instagram} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Youtube link</label>
                              <input type="url" class="form-control" onChange={(e) => setYoutube(e.target.value)} value={youtube} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Linkedin link</label>
                              <input type="url" class="form-control" onChange={(e) => setLinkedin(e.target.value)} value={linkedin} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Tiktok link</label>
                              <input type="url" class="form-control" onChange={(e) => setTiktok(e.target.value)} value={tiktok} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>Pinterest link</label>
                              <input type="url" class="form-control" onChange={(e) => setPinterest(e.target.value)} value={pinterest} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>URL of your website</label>
                              <input type="url" class="form-control" onChange={(e) => setWebsite(e.target.value)} value={website} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Retreat website URL</label>

                              <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Recipient's username" onChange={(e) => setSubDomain(e.target.value)} value={subdomain} />
                                <div class="input-group-append">
                                  <span class="input-group-text" id="basic-addon2">
                                    .iamfit4travel.com
                                  </span>
                                </div>
                              </div>
                              <small class="form-text text-muted">
                                If this is the first time working with us, please choose the URL for the retreat website we're building for you. It will be structured as YourBusinessName.iamfit4travel.com (ex: ABC Yoga can have their website named as abcyoga.iamfit4travel.com)
                              </small>
                            </div>
                          </div>

                          {marketingCollateral == "cohost" ? (
                            <>
                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>About Me Bio (Host 1)</label>
                                  <SunEditor
                                    height={200}
                                    setOptions={{
                                      buttonList: buttonList.formatting,
                                    }}
                                    onChange={setAbout}
                                    setContents={about}
                                  />
                                  {/* <textarea class="form-control" onChange={(e) => setAbout(e.target.value)} value={about}></textarea> */}
                                  <small class="form-text text-muted">can be as long or as short as you’d like. Recommended to have at least 500 words</small>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Designation (Host 1)</label>
                                  <input type="text" class="form-control" onChange={(e) => setDesignation1(e.target.value)} value={designation1} />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>About Me Bio (Host 2)</label>
                                  {/* <textarea class="form-control" onChange={(e) => setAbout2(e.target.value)} value={about2}></textarea> */}
                                  <SunEditor
                                    height={200}
                                    setOptions={{
                                      buttonList: buttonList.formatting,
                                    }}
                                    onChange={setAbout2}
                                    setContents={about2}
                                  />
                                  <small class="form-text text-muted">can be as long or as short as you’d like. Recommended to have at least 500 words</small>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div class="form-group">
                                  <label>Designation (Host 2)</label>
                                  <input type="text" class="form-control" onChange={(e) => setDesignation2(e.target.value)} value={designation2} />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12">
                              <div class="form-group">
                                <label>About Me Bio </label>
                                {/* <textarea class="form-control" onChange={(e) => setAbout(e.target.value)} value={about}></textarea> */}
                                <SunEditor
                                  height={200}
                                  setOptions={{
                                    buttonList: buttonList.formatting,
                                  }}
                                  onChange={setAbout}
                                  setContents={about}
                                />
                                <small class="form-text text-muted">can be as long or as short as you’d like. Recommended to have at least 500 words</small>
                              </div>
                            </div>
                          )}

                          {marketingCollateral == "cohost" && (
                            <div className="col-md-12">
                              <div class="form-group">
                                <label>Brand's visual identity</label>
                                <textarea class="form-control" onChange={(e) => setVisualIdentity(e.target.value)} value={visualIdentity}></textarea>
                                <small class="form-text text-muted">How would you describe your brand's visual identity or the look and feel of your posts on social media?</small>
                              </div>
                            </div>
                          )}

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Special requirements</label>
                              <textarea class="form-control" onChange={(e) => setSpecialRequirements(e.target.value)} value={specialRequirements}></textarea>
                              <small class="form-text text-muted">
                                Is there a particular phrase or text you want to include in the marketing materials? (The trip title in the Fit4Travel itinerary will be used on the grid posts, stories, postcards, posters, and the email templates. Please mention here if any changes are needed)
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>
                                Logo [
                                <small>
                                  <a href={process.env.REACT_APP_IMG_URL + "logo/" + logoFileName} target="_blank">
                                    Preview
                                  </a>
                                </small>
                                ]
                              </label>
                              <input type="file" class="form-control" accept="image/*" onChange={(e) => logoHandler(e)} />
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                            <div class="form-group">
                              <label>
                                Image for Home-Page [
                                <small>
                                  <a href={process.env.REACT_APP_IMG_URL + "home_image/" + homeFileName} target="_blank">
                                    Preview
                                  </a>
                                </small>
                                ]
                              </label>
                              <input type="file" class="form-control" accept="image/*" onChange={(e) => homeImageHandler(e)} />
                              <small class="form-text text-muted"> An image for Home-Page “About Me” section, minimum size: 640px width. (A horizontal image/ landscape orientation)</small>
                            </div>
                          </div> */}

                          {marketingCollateral == "cohost" && (
                            <>
                              <div className="col-md-6">
                                <div class="form-group">
                                  <label>
                                    Profile Picture (Host 1) [
                                    <small>
                                      <a href={process.env.REACT_APP_IMG_URL + "profile_image/" + profileFileName} target="_blank">
                                        Preview
                                      </a>
                                    </small>
                                    ]
                                  </label>
                                  <input type="file" class="form-control" accept="image/*" onChange={(e) => profileImageHandler(e)} />
                                  <small class="form-text text-muted"> A personal picture (Vertical Portrait), minimum size: 400px width</small>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label>
                                    Profile Picture (Host 2) [
                                    <small>
                                      <a href={process.env.REACT_APP_IMG_URL + "profile_image/" + profile2FileName} target="_blank">
                                        Preview
                                      </a>
                                    </small>
                                    ]
                                  </label>
                                  <input type="file" class="form-control" accept="image/*" onChange={(e) => profileImage2Handler(e)} />
                                  <small class="form-text text-muted"> A personal picture (Vertical Portrait), minimum size: 400px width</small>
                                </div>
                              </div>
                            </>
                          )}

                          {marketingCollateral != "cohost" && (
                            <>
                              <div className="col-md-6">
                                <div class="form-group">
                                  <label>
                                    Profile Picture [
                                    <small>
                                      <a href={process.env.REACT_APP_IMG_URL + "profile_image/" + profileFileName} target="_blank">
                                        Preview
                                      </a>
                                    </small>
                                    ]
                                  </label>
                                  <input type="file" class="form-control" accept="image/*" onChange={(e) => profileImageHandler(e)} />
                                  <small class="form-text text-muted"> A personal picture (Vertical Portrait), minimum size: 400px width</small>
                                </div>
                              </div>
                            </>
                          )}

                          <hr />
                          <div className="col-md-6">
                            <div class="form-group">
                              <label>
                                About Background [
                                <small>
                                  <a href={process.env.REACT_APP_IMG_URL + "about/" + aboutFileName} target="_blank">
                                    Preview
                                  </a>
                                </small>
                                ]
                              </label>
                              <input type="file" class="form-control" accept="image/*" onChange={(e) => aboutImageHandler(e)} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>
                                Current Trip Background [
                                <small>
                                  <a href={process.env.REACT_APP_IMG_URL + "trip/" + tripFileName} target="_blank">
                                    Preview
                                  </a>
                                </small>
                                ]
                              </label>
                              <input type="file" class="form-control" accept="image/*" onChange={(e) => tripImageHandler(e)} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="form-group">
                              <label>
                                Instagram Background [
                                <small>
                                  <a href={process.env.REACT_APP_IMG_URL + "instagram/" + instagramFileName} target="_blank">
                                    Preview
                                  </a>
                                </small>
                                ]
                              </label>
                              <input type="file" class="form-control" accept="image/*" onChange={(e) => instagramImageHandler(e)} />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Extra Code Snipt</label>
                              <textarea class="form-control" onChange={(e) => setCodeSnipt(e.target.value)} value={codeSnipt}></textarea>
                              <small class="form-text text-muted">Google code or JavaScript code snippets to the head section of subdomain</small>
                            </div>
                          </div>

                          <hr />

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>*Would you like any additional customizations to your website?</label>
                              <select class="form-control" onChange={(e) => setAdditionalCustomizations(e.target.value)} value={additionalCustomizations}>
                                <option value="Yes">Yes, please contact me with details</option>
                                <option value="No">No, I do not require additional customizations</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <label>Would you like to receive our complimentary marketing material design assets for this trip?</label>

                              <div className="row">
                                <div className="col-md-6">
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={marketingMaterial.includes("Instagram")} onChange={() => handleCheckboxChange("Instagram")} />
                                    <label class="form-check-label">Instagram grid posts (social media posts)</label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={marketingMaterial.includes("Stories")} onChange={() => handleCheckboxChange("Stories")} />
                                    <label class="form-check-label">Stories</label>
                                  </div>{" "}
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={marketingMaterial.includes("Poster")} onChange={() => handleCheckboxChange("Poster")} />
                                    <label class="form-check-label">Poster designs</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={marketingMaterial.includes("Email")} onChange={() => handleCheckboxChange("Email")} />
                                    <label class="form-check-label">Email templates</label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" checked={marketingMaterial.includes("Postcard")} onChange={() => handleCheckboxChange("Postcard")} />
                                    <label class="form-check-label">Postcard designs</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <button className="btn btn-primary w-100" type="button" disable={!Saving} onClick={() => handleSave()}>
                              {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="products" title="Products">
                    <DomainProducts subdomainId={selectedId} />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
