import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  const [destinationsView, setDestinationsView] = useState(false);
  const [destinationsAdd, setDestinationsAdd] = useState(false);
  const [destinationsEdit, setDestinationsEdit] = useState(false);
  const [destinationsDelete, setDestinationsDelete] = useState(false);

  const [experiencesView, setExperiencesView] = useState(false);
  const [experiencesAdd, setExperiencesAdd] = useState(false);
  const [experiencesEdit, setExperiencesEdit] = useState(false);
  const [experiencesDelete, setExperiencesDelete] = useState(false);

  const [productsView, setProductsView] = useState(false);
  const [productsAdd, setProductsAdd] = useState(false);
  const [productsEdit, setProductsEdit] = useState(false);
  const [productsDelete, setProductsDelete] = useState(false);

  const [subDomainsView, setsubDomainsView] = useState(false);
  const [subDomainsAdd, setsubDomainsAdd] = useState(false);
  const [subDomainsEdit, setsubDomainsEdit] = useState(false);
  const [subDomainsDelete, setsubDomainsDelete] = useState(false);

  const [slidersView, setSlidersView] = useState(false);
  const [slidersAdd, setSlidersAdd] = useState(false);
  const [slidersEdit, setSlidersEdit] = useState(false);
  const [slidersDelete, setSlidersDelete] = useState(false);

  const [partnerUsView, setPartnerUsView] = useState(false);
  const [partnerUsAdd, setPartnerUsAdd] = useState(false);
  const [partnerUsEdit, setPartnerUsEdit] = useState(false);
  const [partnerUsDelete, setPartnerUsDelete] = useState(false);

  const [blogsView, setBlogsView] = useState(false);
  const [blogsAdd, setBlogsAdd] = useState(false);
  const [blogsEdit, setBlogsEdit] = useState(false);
  const [blogsDelete, setBlogsDelete] = useState(false);

  const [travelFAQView, setTravelFAQView] = useState(false);
  const [travelFAQAdd, setTravelFAQAdd] = useState(false);
  const [travelFAQEdit, setTravelFAQEdit] = useState(false);
  const [travelFAQDelete, setTravelFAQDelete] = useState(false);

  const [testimonialsFAQView, setTestimonialsFAQView] = useState(false);
  const [testimonialsFAQAdd, setTestimonialsFAQAdd] = useState(false);
  const [testimonialsFAQEdit, setTestimonialsFAQEdit] = useState(false);
  const [testimonialsFAQDelete, setTestimonialsFAQDelete] = useState(false);

  const [otherFAQView, setOtherFAQView] = useState(false);
  const [otherFAQAdd, setOtherFAQAdd] = useState(false);
  const [otherFAQEdit, setOtherFAQEdit] = useState(false);
  const [otherFAQDelete, setOtherFAQDelete] = useState(false);

  const [settingsView, setSettingsView] = useState(false);
  const [settingsAdd, setSettingsAdd] = useState(false);
  const [settingsEdit, setSettingsEdit] = useState(false);
  const [settingsDelete, setSettingsDelete] = useState(false);

  const [roleView, setRoleView] = useState(false);
  const [roleAdd, setRoleAdd] = useState(false);
  const [roleEdit, setRoleEdit] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);

  const [userView, setUserView] = useState(false);
  const [userAdd, setUserAdd] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [userDelete, setUserDelete] = useState(false);

  async function handleRoles() {
    if (!title && title == "") {
      toast.error("Enter title");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/role/add",
        {
          title: title,
          access: {
            destinations: {
              view: destinationsView,
              add: destinationsAdd,
              edit: destinationsEdit,
              delete: destinationsDelete,
            },

            experiences: {
              view: experiencesView,
              add: experiencesAdd,
              edit: experiencesEdit,
              delete: experiencesDelete,
            },

            products: {
              view: productsView,
              add: productsAdd,
              edit: productsEdit,
              delete: productsDelete,
            },

            subDomains: {
              view: subDomainsView,
              add: subDomainsAdd,
              edit: subDomainsEdit,
              delete: subDomainsDelete,
            },

            sliders: {
              view: slidersView,
              add: slidersAdd,
              edit: slidersEdit,
              delete: slidersDelete,
            },

            partnerUs: {
              view: partnerUsView,
              add: partnerUsAdd,
              edit: partnerUsEdit,
              delete: partnerUsDelete,
            },

            blogs: {
              view: blogsView,
              add: blogsAdd,
              edit: blogsEdit,
              delete: blogsDelete,
            },

            travelFAQ: {
              view: travelFAQView,
              add: travelFAQAdd,
              edit: travelFAQEdit,
              delete: travelFAQDelete,
            },

            otherFAQ: {
              view: otherFAQView,
              add: otherFAQAdd,
              edit: otherFAQEdit,
              delete: otherFAQDelete,
            },

            testimonials: {
              view: testimonialsFAQView,
              add: testimonialsFAQAdd,
              edit: testimonialsFAQEdit,
              delete: testimonialsFAQDelete,
            },

            settings: {
              view: settingsView,
              add: settingsAdd,
              edit: settingsEdit,
              delete: settingsDelete,
            },

            roles: {
              view: roleView,
              add: roleAdd,
              edit: roleEdit,
              delete: roleDelete,
            },

            users: {
              view: userView,
              add: userAdd,
              edit: userEdit,
              delete: userDelete,
            },
          },
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/role/update/" + id,
        {
          title: title,
          access: {
            destinations: {
              view: destinationsView,
              add: destinationsAdd,
              edit: destinationsEdit,
              delete: destinationsDelete,
            },

            experiences: {
              view: experiencesView,
              add: experiencesAdd,
              edit: experiencesEdit,
              delete: experiencesDelete,
            },

            products: {
              view: productsView,
              add: productsAdd,
              edit: productsEdit,
              delete: productsDelete,
            },

            subDomains: {
              view: subDomainsView,
              add: subDomainsAdd,
              edit: subDomainsEdit,
              delete: subDomainsDelete,
            },

            sliders: {
              view: slidersView,
              add: slidersAdd,
              edit: slidersEdit,
              delete: slidersDelete,
            },

            partnerUs: {
              view: partnerUsView,
              add: partnerUsAdd,
              edit: partnerUsEdit,
              delete: partnerUsDelete,
            },

            blogs: {
              view: blogsView,
              add: blogsAdd,
              edit: blogsEdit,
              delete: blogsDelete,
            },

            travelFAQ: {
              view: travelFAQView,
              add: travelFAQAdd,
              edit: travelFAQEdit,
              delete: travelFAQDelete,
            },

            otherFAQ: {
              view: otherFAQView,
              add: otherFAQAdd,
              edit: otherFAQEdit,
              delete: otherFAQDelete,
            },

            testimonials: {
              view: testimonialsFAQView,
              add: testimonialsFAQAdd,
              edit: testimonialsFAQEdit,
              delete: testimonialsFAQDelete,
            },

            settings: {
              view: settingsView,
              add: settingsAdd,
              edit: settingsEdit,
              delete: settingsDelete,
            },
            roles: {
              view: roleView,
              add: roleAdd,
              edit: roleEdit,
              delete: roleDelete,
            },

            users: {
              view: userView,
              add: userAdd,
              edit: userEdit,
              delete: userDelete,
            },
          },
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/role/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/role/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Roles"}>
              {userData.role && userData.role.access.roles.add && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setTitle("");
                      setMode("add");
                      setDestinationsView(false);
                      setDestinationsAdd(false);
                      setDestinationsEdit(false);
                      setDestinationsDelete(false);
                      setExperiencesView(false);
                      setExperiencesAdd(false);
                      setExperiencesEdit(false);
                      setExperiencesDelete(false);
                      setProductsView(false);
                      setProductsAdd(false);
                      setProductsEdit(false);
                      setProductsDelete(false);
                      setsubDomainsView(false);
                      setsubDomainsAdd(false);
                      setsubDomainsEdit(false);
                      setsubDomainsDelete(false);
                      setSlidersView(false);
                      setSlidersAdd(false);
                      setSlidersEdit(false);
                      setSlidersDelete(false);
                      setBlogsView(false);
                      setBlogsAdd(false);
                      setBlogsEdit(false);
                      setBlogsDelete(false);
                      setTravelFAQView(false);
                      setTravelFAQAdd(false);
                      setTravelFAQEdit(false);
                      setTravelFAQDelete(false);
                      setOtherFAQView(false);
                      setOtherFAQAdd(false);
                      setOtherFAQEdit(false);
                      setOtherFAQDelete(false);

                      setTestimonialsFAQView(false);
                      setTestimonialsFAQAdd(false);
                      setTestimonialsFAQEdit(false);
                      setTestimonialsFAQDelete(false);

                      setSettingsView(false);
                      setSettingsAdd(false);
                      setSettingsEdit(false);
                      setSettingsDelete(false);

                      setRoleView(false);
                      setRoleAdd(false);
                      setRoleEdit(false);
                      setRoleDelete(false);

                      setUserView(false);
                      setUserAdd(false);
                      setUserEdit(false);
                      setUserDelete(false);

                      setPartnerUsView(false);
                      setPartnerUsAdd(false);
                      setPartnerUsEdit(false);
                      setPartnerUsDelete(false);
                    }}
                  />
                </li>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Title</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.title}</Td>

                            <Td>
                              <Box className="mc-table-action">
                                {userData.role && userData.role.access.roles.edit && (
                                  <Anchor
                                    href="#"
                                    title="Edit"
                                    className="material-icons edit"
                                    onClick={() => {
                                      setMode("edit");
                                      setTitle(item.title);
                                      setId(item._id);
                                      setShow(true);

                                      setDestinationsView(item.access.destinations.view);
                                      setDestinationsAdd(item.access.destinations.add);
                                      setDestinationsEdit(item.access.destinations.edit);
                                      setDestinationsDelete(item.access.destinations.delete);

                                      setExperiencesView(item.access.experiences.view);
                                      setExperiencesAdd(item.access.experiences.add);
                                      setExperiencesEdit(item.access.experiences.edit);
                                      setExperiencesDelete(item.access.experiences.delete);

                                      setProductsView(item.access.products.view);
                                      setProductsAdd(item.access.products.add);
                                      setProductsEdit(item.access.products.edit);
                                      setProductsDelete(item.access.products.delete);

                                      setsubDomainsView(item.access.subDomains.view);
                                      setsubDomainsAdd(item.access.subDomains.add);
                                      setsubDomainsEdit(item.access.subDomains.edit);
                                      setsubDomainsDelete(item.access.subDomains.delete);

                                      setSlidersView(item.access.sliders.view);
                                      setSlidersAdd(item.access.sliders.add);
                                      setSlidersEdit(item.access.sliders.edit);
                                      setSlidersDelete(item.access.sliders.delete);

                                      setPartnerUsView(item.access.partnerUs && item.access.partnerUs.view);
                                      setPartnerUsAdd(item.access.partnerUs && item.access.partnerUs.add);
                                      setPartnerUsEdit(item.access.partnerUs && item.access.partnerUs.edit);
                                      setPartnerUsDelete(item.access.partnerUs && item.access.partnerUs.delete);

                                      setBlogsView(item.access.blogs.view);
                                      setBlogsAdd(item.access.blogs.add);
                                      setBlogsEdit(item.access.blogs.edit);
                                      setBlogsDelete(item.access.blogs.delete);

                                      setTravelFAQView(item.access.travelFAQ.view);
                                      setTravelFAQAdd(item.access.travelFAQ.add);
                                      setTravelFAQEdit(item.access.travelFAQ.edit);
                                      setTravelFAQDelete(item.access.travelFAQ.delete);

                                      setOtherFAQView(item.access.otherFAQ.view);
                                      setOtherFAQAdd(item.access.otherFAQ.add);
                                      setOtherFAQEdit(item.access.otherFAQ.edit);
                                      setOtherFAQDelete(item.access.otherFAQ.delete);

                                      setTestimonialsFAQView(item.access.testimonials.view);
                                      setTestimonialsFAQAdd(item.access.testimonials.add);
                                      setTestimonialsFAQEdit(item.access.testimonials.edit);
                                      setTestimonialsFAQDelete(item.access.testimonials.delete);

                                      setSettingsView(item.access.settings.view);
                                      setSettingsAdd(item.access.settings.add);
                                      setSettingsEdit(item.access.settings.edit);
                                      setSettingsDelete(item.access.settings.delete);

                                      setRoleView(item.access.roles.view);
                                      setRoleAdd(item.access.roles.add);
                                      setRoleEdit(item.access.roles.edit);
                                      setRoleDelete(item.access.roles.delete);

                                      setUserView(item.access.users.view);
                                      setUserAdd(item.access.users.add);
                                      setUserEdit(item.access.users.edit);
                                      setUserDelete(item.access.users.delete);
                                    }}
                                  >
                                    <Icon>edit</Icon>
                                  </Anchor>
                                )}

                                {userData.role && userData.role.access.roles.delete && (
                                  <Button
                                    title="Delete"
                                    className="material-icons delete"
                                    onClick={() => {
                                      setId(item._id);
                                      setShowSwal(true);
                                    }}
                                  >
                                    <Icon>delete</Icon>
                                  </Button>
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} Role</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="title" type="text" fieldSize="w-100 h-md" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col xl={12}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Module</th>
                    <th scope="col">View</th>
                    <th scope="col">Add</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Destinations</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={destinationsView}
                        onClick={() => {
                          if (destinationsView) {
                            setDestinationsView(false);
                          } else {
                            setDestinationsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={destinationsAdd}
                        onClick={() => {
                          if (destinationsAdd) {
                            setDestinationsAdd(false);
                          } else {
                            setDestinationsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={destinationsEdit}
                        onClick={() => {
                          if (destinationsEdit) {
                            setDestinationsEdit(false);
                          } else {
                            setDestinationsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={destinationsDelete}
                        onClick={() => {
                          if (destinationsDelete) {
                            setDestinationsDelete(false);
                          } else {
                            setDestinationsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Experiences</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={experiencesView}
                        onClick={() => {
                          if (experiencesView) {
                            setExperiencesView(false);
                          } else {
                            setExperiencesView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={experiencesAdd}
                        onClick={() => {
                          if (experiencesAdd) {
                            setExperiencesAdd(false);
                          } else {
                            setExperiencesAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={experiencesEdit}
                        onClick={() => {
                          if (experiencesEdit) {
                            setExperiencesEdit(false);
                          } else {
                            setExperiencesEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={experiencesDelete}
                        onClick={() => {
                          if (experiencesDelete) {
                            setExperiencesDelete(false);
                          } else {
                            setExperiencesDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Products</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={productsView}
                        onClick={() => {
                          if (productsView) {
                            setProductsView(false);
                          } else {
                            setProductsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={productsAdd}
                        onClick={() => {
                          if (productsAdd) {
                            setProductsAdd(false);
                          } else {
                            setProductsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={productsEdit}
                        onClick={() => {
                          if (productsEdit) {
                            setProductsEdit(false);
                          } else {
                            setProductsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={productsDelete}
                        onClick={() => {
                          if (productsDelete) {
                            setProductsDelete(false);
                          } else {
                            setProductsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Sub Domains</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subDomainsView}
                        onClick={() => {
                          if (subDomainsView) {
                            setsubDomainsView(false);
                          } else {
                            setsubDomainsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subDomainsAdd}
                        onClick={() => {
                          if (subDomainsAdd) {
                            setsubDomainsAdd(false);
                          } else {
                            setsubDomainsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subDomainsEdit}
                        onClick={() => {
                          if (subDomainsEdit) {
                            setsubDomainsEdit(false);
                          } else {
                            setsubDomainsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subDomainsDelete}
                        onClick={() => {
                          if (subDomainsDelete) {
                            setsubDomainsDelete(false);
                          } else {
                            setsubDomainsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Sliders</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={slidersView}
                        onClick={() => {
                          if (slidersView) {
                            setSlidersView(false);
                          } else {
                            setSlidersView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={slidersAdd}
                        onClick={() => {
                          if (slidersAdd) {
                            setSlidersAdd(false);
                          } else {
                            setSlidersAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={slidersEdit}
                        onClick={() => {
                          if (slidersEdit) {
                            setSlidersEdit(false);
                          } else {
                            setSlidersEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={slidersDelete}
                        onClick={() => {
                          if (slidersDelete) {
                            setSlidersDelete(false);
                          } else {
                            setSlidersDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Partner with us</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={partnerUsView}
                        onClick={() => {
                          if (partnerUsView) {
                            setPartnerUsView(false);
                          } else {
                            setPartnerUsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={partnerUsAdd}
                        onClick={() => {
                          if (partnerUsAdd) {
                            setPartnerUsAdd(false);
                          } else {
                            setPartnerUsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={partnerUsEdit}
                        onClick={() => {
                          if (partnerUsEdit) {
                            setPartnerUsEdit(false);
                          } else {
                            setPartnerUsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={partnerUsDelete}
                        onClick={() => {
                          if (partnerUsDelete) {
                            setPartnerUsDelete(false);
                          } else {
                            setPartnerUsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Blogs</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={blogsView}
                        onClick={() => {
                          if (blogsView) {
                            setBlogsView(false);
                          } else {
                            setBlogsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={blogsAdd}
                        onClick={() => {
                          if (blogsAdd) {
                            setBlogsAdd(false);
                          } else {
                            setBlogsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={blogsEdit}
                        onClick={() => {
                          if (blogsEdit) {
                            setBlogsEdit(false);
                          } else {
                            setBlogsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={blogsDelete}
                        onClick={() => {
                          if (blogsDelete) {
                            setBlogsDelete(false);
                          } else {
                            setBlogsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Travel FAQ</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={travelFAQView}
                        onClick={() => {
                          if (travelFAQView) {
                            setTravelFAQView(false);
                          } else {
                            setTravelFAQView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={travelFAQAdd}
                        onClick={() => {
                          if (travelFAQAdd) {
                            setTravelFAQAdd(false);
                          } else {
                            setTravelFAQAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={travelFAQEdit}
                        onClick={() => {
                          if (travelFAQEdit) {
                            setTravelFAQEdit(false);
                          } else {
                            setTravelFAQEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={travelFAQDelete}
                        onClick={() => {
                          if (travelFAQDelete) {
                            setTravelFAQDelete(false);
                          } else {
                            setTravelFAQDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Ambassador FAQ</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={otherFAQView}
                        onClick={() => {
                          if (otherFAQView) {
                            setOtherFAQView(false);
                          } else {
                            setOtherFAQView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={otherFAQAdd}
                        onClick={() => {
                          if (otherFAQAdd) {
                            setOtherFAQAdd(false);
                          } else {
                            setOtherFAQAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={otherFAQEdit}
                        onClick={() => {
                          if (otherFAQEdit) {
                            setOtherFAQEdit(false);
                          } else {
                            setOtherFAQEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={otherFAQDelete}
                        onClick={() => {
                          if (otherFAQDelete) {
                            setOtherFAQDelete(false);
                          } else {
                            setOtherFAQDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Testimonials</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={testimonialsFAQView}
                        onClick={() => {
                          if (testimonialsFAQView) {
                            setTestimonialsFAQView(false);
                          } else {
                            setTestimonialsFAQView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={testimonialsFAQAdd}
                        onClick={() => {
                          if (testimonialsFAQAdd) {
                            setTestimonialsFAQAdd(false);
                          } else {
                            setTestimonialsFAQAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={testimonialsFAQEdit}
                        onClick={() => {
                          if (testimonialsFAQEdit) {
                            setTestimonialsFAQEdit(false);
                          } else {
                            setTestimonialsFAQEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={testimonialsFAQDelete}
                        onClick={() => {
                          if (testimonialsFAQDelete) {
                            setTestimonialsFAQDelete(false);
                          } else {
                            setTestimonialsFAQDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Settings</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={settingsView}
                        onClick={() => {
                          if (settingsView) {
                            setSettingsView(false);
                          } else {
                            setSettingsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={settingsAdd}
                        disabled
                        onClick={() => {
                          if (settingsAdd) {
                            setSettingsAdd(false);
                          } else {
                            setSettingsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={settingsEdit}
                        onClick={() => {
                          if (settingsEdit) {
                            setSettingsEdit(false);
                          } else {
                            setSettingsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={settingsDelete}
                        onClick={() => {
                          if (settingsDelete) {
                            setSettingsDelete(false);
                          } else {
                            setSettingsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Role</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleView}
                        onClick={() => {
                          if (roleView) {
                            setRoleView(false);
                          } else {
                            setRoleView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleAdd}
                        onClick={() => {
                          if (roleAdd) {
                            setRoleAdd(false);
                          } else {
                            setRoleAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleEdit}
                        onClick={() => {
                          if (roleEdit) {
                            setRoleEdit(false);
                          } else {
                            setRoleEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleDelete}
                        onClick={() => {
                          if (roleDelete) {
                            setRoleDelete(false);
                          } else {
                            setRoleDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>User</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userView}
                        onClick={() => {
                          if (userView) {
                            setUserView(false);
                          } else {
                            setUserView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userAdd}
                        onClick={() => {
                          if (userAdd) {
                            setUserAdd(false);
                          } else {
                            setUserAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userEdit}
                        onClick={() => {
                          if (userEdit) {
                            setUserEdit(false);
                          } else {
                            setUserEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userDelete}
                        onClick={() => {
                          if (userDelete) {
                            setUserDelete(false);
                          } else {
                            setUserDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleRoles()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
