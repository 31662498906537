import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
export default function CRM() {
  const { token } = useSelector((state) => state.counter);
  const [data, setData] = useState([]);
  useEffect(() => {
    //fetchData(1);
  }, []);

  async function fetchData() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/dasborad/admin", options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Dashboard"}></Breadcrumb>
        </Col>

        <div className="text-center" style={{ minHeight: "100vh" }}>
          Coming Soon
        </div>

        {/* <div className="row">
          <div class="col-xl-3">
            <div class="mc-analytics-card bg-primary">
              <div class="mc-analytics-card-group">
                <div class="mc-analytics-card-content">
                  <h3>{data.stats && data.stats.destinations}</h3>
                  <p>Services</p>
                </div>
              </div>
              <div class="recharts-responsive-container" width="190.75" height="40">
                <div class="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div class="recharts-default-tooltip">
                      <p class="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3">
            <div class="mc-analytics-card bg-primary">
              <div class="mc-analytics-card-group">
                <div class="mc-analytics-card-content">
                  <h3>{data.stats && data.stats.experiences}</h3>
                  <p>Experiences</p>
                </div>
              </div>
              <div class="recharts-responsive-container" width="190.75" height="40">
                <div class="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div class="recharts-default-tooltip">
                      <p class="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3">
            <div class="mc-analytics-card bg-primary">
              <div class="mc-analytics-card-group">
                <div class="mc-analytics-card-content">
                  <h3>{data.stats && data.stats.products}</h3>
                  <p>Products</p>
                </div>
              </div>
              <div class="recharts-responsive-container" width="190.75" height="40">
                <div class="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div class="recharts-default-tooltip">
                      <p class="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3">
            <div class="mc-analytics-card bg-primary">
              <div class="mc-analytics-card-group">
                <div class="mc-analytics-card-content">
                  <h3>{data.stats && data.stats.blogs}</h3>
                  <p>Blogs</p>
                </div>
              </div>
              <div class="recharts-responsive-container" width="190.75" height="40">
                <div class="recharts-wrapper" role="region">
                  <div tabindex="-1" role="dialog" class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                    <div class="recharts-default-tooltip">
                      <p class="recharts-tooltip-label"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <Col xl={6}>
          <div className="p-3 bg-white">
            <h4>Most viewed destinations</h4> <br />
            <div class="mc-table-responsive">
              <table class="mc-table">
                <thead class="mc-table-head">
                  <tr>
                    <th>Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody class="mc-table-body">
                  {data.recents &&
                    data.recents.destinations?.length > 0 &&
                    data.recents.destinations.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div class="mc-table-profile">
                            <p>
                              <a href={"https://fitventuretravel.com/destinations/" + item.slug} target="_blank">
                                <FaExternalLinkSquareAlt /> {item.title}
                              </a>
                            </p>
                          </div>
                        </td>
                        <td>{item.counter}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
        <Col xl={6}>
          <div className="p-3 bg-white">
            <h4>Most viewed experiences</h4> <br />
            <div class="mc-table-responsive">
              <table class="mc-table">
                <thead class="mc-table-head">
                  <tr>
                    <th>Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody class="mc-table-body">
                  {data.recents &&
                    data.recents.experiences?.length > 0 &&
                    data.recents.experiences.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div class="mc-table-profile">
                            <p>
                              <a href={"https://fitventuretravel.com/experiences/" + item.slug} target="_blank">
                                <FaExternalLinkSquareAlt /> {item.title}
                              </a>
                            </p>
                          </div>
                        </td>
                        <td>{item.counter}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xl={6}>
          <div className="p-3 bg-white">
            <h4>Most viewed products</h4> <br />
            <div class="mc-table-responsive">
              <table class="mc-table">
                <thead class="mc-table-head">
                  <tr>
                    <th>Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody class="mc-table-body">
                  {data.recents &&
                    data.recents.products?.length > 0 &&
                    data.recents.products.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div class="mc-table-profile">
                            <p>
                              <a href={"https://fitventuretravel.com/product/" + item.slug} target="_blank">
                                <FaExternalLinkSquareAlt /> {item.title}
                              </a>
                            </p>
                          </div>
                        </td>
                        <td>{item.counter}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xl={6}>
          <div className="p-3 bg-white">
            <h4>Most viewed blogs</h4> <br />
            <div class="mc-table-responsive">
              <table class="mc-table">
                <thead class="mc-table-head">
                  <tr>
                    <th>Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody class="mc-table-body">
                  {data.recents &&
                    data.recents.blogs?.length > 0 &&
                    data.recents.blogs.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div class="mc-table-profile">
                            <p>
                              <a href={"https://fitventuretravel.com/blog/" + item.slug} target="_blank">
                                <FaExternalLinkSquareAlt /> {item.title}
                              </a>
                            </p>
                          </div>
                        </td>
                        <td>{item.counter}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col> */}
      </Row>
    </PageLayout>
  );
}
